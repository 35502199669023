<template>
  <section class="home">
    <img
      src="@/assets/images/qundrox-startpage-image.jpg"
      alt=""
      class="center"
    />
    <div class="readFriendlyLayout">
      <h2>Welcome to Quendrox</h2>
      <p>
        We are a company in the south of Sweden and with our headquarter in the
        heart of Skåne, we are close to the markets throughout the region. Our
        solid experience and the unique competence that we can attract put us in
        a perfect position to provide the competence and tools needed by our
        customers to create certified products and services. We will be your
        trustable partner and we will do our utmost to make your business
        thrive.
      </p>
    </div>
    <div class="menuOptionExplanations">
      <router-link to="/quendrox-ha" class="menuOptionExplanation">
        <h3>Quendrox HA</h3>
        <img
          class="menuOptionExplanation__image"
          src="@/assets/images/quendrox-home-automation.png"
          alt="Quendrox HA"
        />
      </router-link>
      <router-link to="/quendrox-consulting" class="menuOptionExplanation">
        <h3>Quendrox consulting</h3>
        <img
          class="menuOptionExplanation__image"
          src="@/assets/images/quendrox-consulting.png"
          alt="Quendrox HA"
        />
      </router-link>
      <router-link to="/quendrox-services" class="menuOptionExplanation">
        <h3>Quendrox services</h3>
        <img
          class="menuOptionExplanation__image"
          src="@/assets/images/quendrox-services.png"
          alt="Quendrox HA"
        />
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  components: {}
};
</script>

<style scoped lang="scss">
.center {
  display: block;
  margin: auto;
  max-width: 90%;
}

.home {
  img {
    border-radius: 10px;
  }
}

.menuOptionExplanations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  .menuOptionExplanation {
    flex-basis: 33%;
    text-decoration: none;
    border-radius: 10px;

    &__image {
      background-color: var(--light-accent);
      max-width: 90%;
      &:hover {
        background-color: var(--dark-accent);
        transition: 0.2s;
      }
    }
  }
}

@media (min-width: 600px) {
  .menuOptionExplanations {
    flex-direction: row;
  }
}
</style>
